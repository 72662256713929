































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import moment from 'moment'

@Component({ name: 'Account', components: { } })
export default class Account extends VueBase {
  private tableData = {
    data: [],
    currentPage: 1,
    pageSize: 10,
    total: 0
  }
  private formData: any = {
    username: "",
    password: "",
    password2: "",
    email: "",
    phone: "",
    role: "",
    is_global_permission: true,
    related_project_group: [],
    association: true
  }
  private id: any = ''
  private rules: any = {
    username: [{ required: true, message: '请输入账号名称', trigger: 'blur' }],
    password: [{ validator: this.validatePass, trigger: 'blur' }],
    password2: [{ validator: this.validatePass2, trigger: 'blur' }],
    email: [{ required: true, message: '请输入邮箱', trigger: 'blur' },{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
    role: [{ required: true, message: '请选择账号角色', trigger: 'blur' }],
    phone: [{ validator: this.phoneValidator, trigger: ['blur', 'change'] }]
  }

  validatePass2(rule: any, value: any, callback:any) {
    if (value === '' && !this.id) {
      callback(new Error('请再次输入密码'));
    } else if (value.length < 8) {
       callback(new Error('密码长度不能少于8位'));
    } else if (value !== this.formData.password) {
      console.log(value, this.formData.password, 999)
      callback(new Error('两次输入密码不一致!'));
    } else {
      callback();
    }
  }
  validatePass(rule: any, value: any, callback:any) {
    if (value === '' && !this.id) {
      callback(new Error('请输入账号密码'));
    } else if (value.length < 8) {
       callback(new Error('密码长度不能少于8位'));
    }  else {
      callback();
    }
  }
  phoneValidator(rule: any, value: any, callback:any) {
    var reg = /.*[0-9]{7}/
    if (value && !reg.test(value)) {
      callback(new Error('格式错误'));
    } else {
      callback();
    }
  }

  private dialogVisible: boolean = false
  private dialogTitle: string = ''
  handleAdd() {
    this.id = ''
    this.dialogTitle = '新增'
    this.dialogVisible = true
  }
  private dialogLoading = false
  async handleEdit(row: any) {
    this.id = row.id
    this.dialogTitle = '编辑'
    this.dialogVisible = true
    this.dialogLoading = true
    let { data, status } = await this.services.authority.getUser(row.id)
    this.dialogLoading = false
    if (status !==201 ) return
    this.formData.username = data.username
    this.formData.email = data.email
    this.formData.phone = data.phone
    this.formData.role = data.role.id
    this.options = this.unique([data.role, ...this.options])
    this.formData.is_global_permission = data.is_global_permission
    if(!this.formData.is_global_permission) {
      this.formData.related_project_group = data.related_project_group.map((item: any) => item.id)
      this.formData.association = this.formData.related_project_group.length > 0 ? true : false
      this.groups = this.unique([...data.related_project_group, ...this.groups])
    }
    // console.log('formData', this.formData)
  }
  unique(arr: any) {
    var result = arr.reduce((unique: any, o: any) => {
        if(!unique.some((obj: any) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
    },[]);
    return result
  }
  async getSelectOptions() {
    this.getGroups()
    this.getProjects()
    this.getRoles()
  }
  handleClose() {
    (this.$refs.ruleForm as any)?.resetFields()
    this.formData.related_project_group = []
    this.formData.is_global_permission = true
    this.formData.association = true
    this.dialogVisible = false
  }
  handleChange(val: any) {
    if (val) {
      this.formData.related_project_group = []
    }
  }
  handleChange2(val: any) {
    this.$forceUpdate()
    if (!val) {
      this.formData.related_project_group = []
    }
  }

  handleSubmit() {
    (this.$refs.ruleForm as any)?.validate(async(valid: boolean) => {
      if (valid) {
        let res: any = {}
        let msg: any = '创建'
        if (!this.formData.is_global_permission && this.formData.association && this.formData.related_project_group.length < 1) {
          this.$message.warning('请选择关联项目')
          return
        }
        let params = {
          ...this.formData,
          phone: this.formData.phone || undefined,
          related_project_group: this.formData.related_project_group.length === 0 ? 
            undefined : this.formData.related_project_group,
          password2: undefined,
          association: undefined
        }
        if (this.id) {
          msg = '编辑'
          res = await this.services.authority.aeditUser(this.id, {
            ...params,
            password: this.formData.password || undefined,
          })
        } else {
          res = await this.services.authority.addUser(params)
        }
        if (res.status !== 201) {
          this.$message.error(res.msg)
          return
        }
        this.$message.success(`账号${msg}成功`)
        this.handleClose()
        this.getList()
        
      }
    })
  }
  private detailVisible: boolean = false
  private detailData: any = {}
  async handleDetail(row: any) {
    this.detailData = {}
    this.detailVisible = true
    let { data, status, msg } = await this.services.authority.getUser(row.id)
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.detailData = data

  }

  async handleDelete(row: any) {
      let { status } = await this.services.authority.deleteUser(row.id)
      status === 201 ? this.$message.success('账号删除成功') : this.$message.error('账号删除失败，请重试')
      this.getList()
  }
  async handleStatus(row :any) {
    let msg: string = ''
    msg = row.is_active ? '禁用' : '启用'
    
      const res: any = await this.services.authority.changeUserStatus(row.id, {
        is_active: !row.is_active
      })
      // console.log(res)
      res.status === 201 ? this.$message.success(`账号${msg}成功`) : this.$message.error(`账号${msg}失败，请重试`)
      this.getList()
  }
  async handleUnlock(row :any) {
    const res: any = await this.services.authority.unlock(row.id)
    res.status === 201 ? this.$message.success(`账号启用成功`) : this.$message.error(`账号启用失败，请重试`)
    this.getList()
  }

  created() {
    this.getList()
    this.getSelectOptions()
  }
  async getList() {
    let { data, status, page, msg } = await this.services.authority.getUserList({
      page: this.tableData.currentPage,
      page_size: this.tableData.pageSize
    })
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.tableData.data = data
    this.tableData.total = page.alltotal
  }
  handleCurrentChange(val: any) {
    this.tableData.currentPage = val
    this.getList()
  }
  formatterTime(time: any) {
    return moment(time).format('YYYY-MM-DD HH:mm')
  }
  private async remoteProject(query: string) {
    this.getProjects(query)
  }
  private projects: any = []
  private async getProjects(name?: string | undefined) {
    const res = await this.services.project.projectList({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      this.projects = res.data
      // console.log('projects1111', this.projects)
    }
  }

  private async remoteGroup(query: string) {
    this.getGroups(query)
  }
  private groups: any = []
  private async getGroups(name?: string | undefined) {
    const res = await this.services.authority.getGroupList({
      page: 1,
      pageSize: 50,
      name: name,
    })
    if (res.status === 201) {
      this.groups = res.data
    }
  }

  private async remoteRole(query: string) {
    this.getRoles(query)
  }
  private options: any = []
  private async getRoles(name?: string | undefined) {
    const res = await this.services.authority.getRoleList({
      page: 1,
      pageSize: 50,
      name: name,
      status: 1
    })
    if (res.status === 201) {
      this.options = res.data
    }
  }

  private showSessionDialog: boolean = false
  private formSession: any = {
    session_expiry: ''
  }
  async handleSet() {
    this.showSessionDialog = true
    const {data, status} = await this.services.authority.getSessionExpiry()
    if (status != 201) return
    this.formSession.session_expiry = data.session_expiry / 3600
  }
  saveSession() {
    (this.$refs.formSession as any).validate(async (val: any) => {
      if (!val) return
      const { status } = await this.services.authority.setSessionExpiry({
        session_expiry: this.formSession.session_expiry * 3600
      })
      status === 201 ? this.$message.success('Session有效期设置成功') : 
      this.$message.error('Session有效期设置失败，请重试');
      this.showSessionDialog = false
    })
  }
}
